<template>
  <div>
    <!--PVUV-->
    <div class="mainAll1">
      <div class="navigatorAll">
        <span
          style="border-right:none"
          @click="active('activeAll1',1)"
          :class="{'active':activeAll1===1}"
        >{{$t('Annual')+$t('DonationPopulation')}}</span>
        <span
          style="border-right:none"
          @click="active('activeAll1',2)"
          :class="{'active':activeAll1===2}"
        >{{$t('Quarterly')+$t('DonationPopulation')}}</span>
        <span
          style="border-right:none"
          @click="active('activeAll1',3)"
          :class="{'active':activeAll1===3}"
        >{{$t('Monthly')+$t('DonationPopulation')}}</span>
        <span
          style="border-right:none"
          @click="active('activeAll1',4)"
          :class="{'active':activeAll1===4}"
        >{{$t('Weekly')+$t('DonationPopulation')}}</span>
        <span
          @click="active('activeAll1',5)"
          :class="{'active':activeAll1===5}"
        >{{$t('Daily')+$t('DonationPopulation')}}</span>
      </div>
      <div class="chartAll1">
        <div
          id="lineAll1"
          class="lineAll1"
        >
        </div>
        <div class="pvuv">
          <p>{{$t('DonationPopulation')}}</p>
          <p>{{UNum[0]}}</p>
        </div>
      </div>
    </div>

    <!--收入-->
    <div class="mainAll1">
      <div class="navigatorAll">
        <span
          style="border-right:none"
          @click="active('activeAll2',1)"
          :class="{'active':activeAll2===1}"
        >{{$t('Annual')+$t('DonationFund')}}</span>
        <span
          style="border-right:none"
          @click="active('activeAll2',2)"
          :class="{'active':activeAll2===2}"
        >{{$t('Quarterly')+$t('DonationFund')}}</span>
        <span
          style="border-right:none"
          @click="active('activeAll2',3)"
          :class="{'active':activeAll2===3}"
        >{{$t('Monthly')+$t('DonationFund')}}</span>
        <span
          style="border-right:none"
          @click="active('activeAll2',4)"
          :class="{'active':activeAll2===4}"
        >{{$t('Weekly')+$t('DonationFund')}}</span>
        <span
          @click="active('activeAll2',5)"
          :class="{'active':activeAll2===5}"
        >{{$t('Daily')+$t('DonationFund')}}</span>
      </div>
      <div class="chartAll1">
        <div
          id="lineAll2"
          class="lineAll1"
        ></div>
        <div class="pvuv">
          <p>{{$t('AllData')+' '+$t('DonationFund')}}</p>
          <p>{{QVNum[0]}}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import * as echarts from "echarts";
import api from "@/fetch/api";
import moment from "moment";

export default {
  name: "",
  components: {},
  data() {
    //读取本地个人信息
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      activeAll1: 1,
      activeAll2: 1,
      chamberId: data.chamberId,
      tenantCode: data.tenantCode,
      today: "",

      UNum: {},
      QVNum: {},
      initData: {
        chamberId: data.chamberId,
        date: "",
        currentAdmin: data.id,
      },
    };
  },
  mounted() {
    const param = { dateType: "YEAR", requestType: [4, 5] };
    this.getData(param);

    this.today = moment(new Date()).format("yyyy-MM-DD HH:mm:ss");
  },
  methods: {
    active(tag, num) {
      this[tag] = num;
      const dateObj = {
        1: "YEAR",
        2: "QUARTER",
        3: "MOUTH",
        4: "WEEK",
        5: "DAY",
      };

      let requestType = tag === "activeAll1" ? 4 : 5;
      const param = { dateType: dateObj[num], requestType: [requestType] };
      this.getData(param);
    },

    //获取数据
    getData({ dateType, requestType }) {
      let params = {
        dateType: dateType === "QUARTER" ? "YEAR" : dateType,
        dataType: 5,
        requestType: requestType,
        chamberId: this.chamberId,
        tenantCode: this.tenantCode,
      };
      api.queryStatisticsData(params).then((res) => {
        if (requestType[0] === 4) {
          const listTime = res.data.donationPeopleNumberData.listTime,
            listDataY1 = res.data.donationPeopleNumberData.listDataY1,
            listDataY2 = res.data.donationPeopleNumberData.listDataY2;
          res.data.donationPeopleNumberData.listTime = this.timeDateFormat(
            dateType,
            listTime
          );
          if (dateType === "QUARTER") {
            res.data.donationPeopleNumberData.listDataY1 = this.dataFormat(listDataY1);
            res.data.donationPeopleNumberData.listDataY2 = this.dataFormat(listDataY2);
          }
          this.drawLineChart1(res.data);
          this.UNum = {
            0: Number(res.data.donationPeopleNumberData.resultParamOne),
            1: Number(res.data.donationPeopleNumberData.resultParamTwo),
          };
        }
        if (requestType[0] === 5 || requestType[1] === 5) {
          const listTime = res.data.donationMoneyNumberData.listTime,
            listDataY1 = res.data.donationMoneyNumberData.listDataY1,
            listDataY2 = res.data.donationMoneyNumberData.listDataY2;
          res.data.donationMoneyNumberData.listTime = this.timeDateFormat(
            dateType,
            listTime
          );
          if (dateType === "QUARTER") {
            res.data.donationMoneyNumberData.listDataY1 = this.dataFormat(listDataY1);
            res.data.donationMoneyNumberData.listDataY2 = this.dataFormat(listDataY2);
          }
          this.drawLineChart2(res.data);
          // this.drawPieChart2();
          this.QVNum = {
            0: Number(res.data.donationMoneyNumberData.resultParamOne),
            1: Number(res.data.donationMoneyNumberData.resultParamTwo),
          };
        }
      });
    },

    //季度数据合并
    dataFormat(arr, start, end) {
      let result = [];
      for (let i = 0; i <= arr.length - 3; i += 3) {
        let sum = Number(arr[i]) + Number(arr[i + 1]) + Number(arr[i + 2]);
        result.push(sum);
      }
      return result;
    },

    //时间日期转化
    timeDateFormat(dateType, listTime) {
      switch (dateType) {
        case "QUARTER":
          listTime = this.formatQuarter(listTime);
          break;
        case "MOUTH":
          listTime = this.formatMonthDay(listTime);
          break;
        case "WEEK":
          listTime = this.formatWeekday(listTime);
          break;
        case "DAY":
          listTime = this.formatTime(listTime);
          break;
      }
      return listTime;
    },

    //合为季度
    formatQuarter(arr) {
      let result = [];
      for (let i = 0; i < arr.length; i += 3) {
        let start = arr[i];
        let end = arr[i + 2] ? arr[i + 2] : arr[arr.length - 1];
        result.push(start + "~" + end);
      }
      return result;
    },

    //月、周转换
    formatDates(arr) {
      let result = [];
      let resultWeek = [];
      for (let i = 0; i < arr.length; i++) {
        let date = new Date(arr[i]);
        let month = date.getMonth() + 1;
        if (month < 10) month = "0" + month;
        let day = date.getDate();
        if (day < 10) day = "0" + day;
        let weekday = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()];
        result.push(month + "-" + day);
        resultWeek.push(month + "-" + day + "(" + weekday + ")");
      }
      return { result, resultWeek };
    },

    //月度
    formatMonthDay(arr) {
      return this.formatDates(arr).result;
    },

    //本周
    formatWeekday(arr) {
      return this.formatDates(arr).resultWeek;
    },

    //当日
    formatTime(arr) {
      let result = [];
      for (let i = 0; i < arr.length; i++) {
        let time = arr[i].split(" ")[1].split(":");
        let hour = time[0];
        let minute = time[1];
        result.push(hour + ":" + minute);
      }
      return result;
    },

    //折线图1
    drawLineChart1(data) {
      const donationPeopleNumberData = data.donationPeopleNumberData;
      let date = this.activeAll1;
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("lineAll1"));
      // 指定图表的配置项和数据
      let option = {
        color: ["#F3B1CA"],
        title: {
          text: this.$t('DonationPopulation'),
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [this.$t('DonationPopulation')],
          icon: "rect",
        },
        xAxis: {
          data: donationPeopleNumberData.listTime,
          axisLabel: {
            interval: date === 2 || date === 4 ? 0 : 2, // 0 强制显示所有坐标
          },
        },
        yAxis: {},
        series: [
          {
            data: donationPeopleNumberData.listDataY2,
            type: "line",
            name: this.$t('DonationPopulation'),
            areaStyle: {
              opacity: 0.5,
              color: "#F3B1CA",
            },
            // emphasis: {
            //   focus: 'series'
            // },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    //折线图2
    drawLineChart2(data) {
      const donationMoneyNumberData = data.donationMoneyNumberData;
      let date = this.activeAll2;
      // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      let myChart = this.$echarts.init(document.getElementById("lineAll2"));
      // 指定图表的配置项和数据
      let option = {
        color: ["#F3B1CA"],
        title: {
          text: this.$t("DonationFund"),
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: [this.$t("DonationFund")],
          icon: "rect",
        },
        xAxis: {
          data: donationMoneyNumberData.listTime,
          axisLabel: {
            interval: date === 2 || date === 4 ? 0 : 2, // 0 强制显示所有坐标
          },
        },
        yAxis: {},
        series: [
          {
            data: donationMoneyNumberData.listDataY2,
            type: "line",
            name: this.$t("DonationFund"),
            areaStyle: {
              opacity: 0.5,
              color: "#F3B1CA",
            },
            // emphasis: {
            //   focus: 'series'
            // },
          },
        ],
      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
  },
};
</script>

<style  lang="scss" scoped>
.active {
  /*background-color: #1C252C;*/
  background-color: #677d95;
  color: white;
}
.mainAll1 {
  /*height:450px;*/
  width: 1600px;
}
.navigatorAll {
  /*width: 1660px;*/
  width: 99%;
  height: 40px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  /*border:1px solid black;*/
  margin: 10px auto;
  & > span {
    line-height: 40px;
    text-align: center;
    border: 1px solid black;
  }
}
.chartAll1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}
.lineAll1 {
  height: 350px;
  width: 45%;
  margin: 10px;
}
.pieAll1 {
  height: 350px;
  width: 55%;
  margin: 10px;
  display: flex;
  justify-content: space-around;
  #pieAll1 {
    width: 740px;
    height: 350px;
  }
  #pieAll2 {
    width: 740px;
    height: 350px;
  }
}
.pvuv {
  border: 1px solid black;
  width: 300px;
  height: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-flow: column nowrap;
  font-size: 20px;
}
</style>